export class ConfigDB {
    static data = {
        settings: {
            layout_type: "ltr",
            layout: "Dubai",
            sidebar: {
                type: "compact-wrapper",
            },
            sidebar_setting: "default-sidebar",
        },
        color: {
            primary_color: "#6362e7",
            secondary_color: "#ffc500",
            mix_background_layout: "light-only",
        },
        router_animation: "fadeIn",
    };
}
export default ConfigDB;
