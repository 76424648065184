import { FC } from "react";
import { Col, Row } from "reactstrap";

import Skeleton from "react-loading-skeleton";

import s from "./CalendarSkeleton.module.css";

const CalendarSkeleton: FC = () => {
    return (
        <Row>
            <Col xl={9}>
                <Skeleton className={s.calendar} />
            </Col>
            <Col xl={3}>
                <Skeleton className={s.sidebar} />
            </Col>
        </Row>
    );
};

export default CalendarSkeleton;
